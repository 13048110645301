.App, body {
  height: 1000px;
  color: #ffffff;
  background-color: #393957; 
}

.session-name {
  margin-bottom: 0px;
}

.session-id {
  margin-top: 6px;
}

.the-timestamp {
  float: right;
  margin-top: 3px;
  font-style: italic;
}

.the-nickname, .the-content {
  margin-top: 3px;
  margin-bottom: 10px;
}

.the-nickname {
  font-weight: bold;
}

.msg-panel {
  position: absolute;
  left: 50%;
  top: 55%;
  width: 1000px;
  transform: translate(-50%, -50%);
}

#msg-textarea {
  width: 1000px;
  height: 60px;
  border-radius: 8px;
  display: block;
}

.series-of-messages {
  padding: 0px 0px 0px 0px;
  overflow: scroll;
  height: 640px;
}

.series-of-messages li {
  list-style-type: none;
}

.series-of-messages li:nth-child(odd) {
  background-color: #393957;
}

.series-of-messages li:nth-child(even) {
  background-color: #5F5F78;
}

.individual-msg {
  padding: 1px 3px 1px 3px;
}

.button-spacer {
  height: 5px;
}

.big-button-spacer {
  height: 10px;
}

#main-logo {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 35%;
  height: 300px;
  width: auto;
}

#home-page-buttons, 
#create-session-enter,
#join-session-enter {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 75%;
}

#create-session-input,
#join-session-input {
  display: block;
  width: 195px;
  border-radius: 6px;
}

.right-items {
  position: absolute;
  right: 170px;
}

.welcome {
  font-style: italic;
  display: inline-block;
  padding: 13px 15px 0 0;
}

.leave-session {
  color: #ffffff;
  background-color: #5f5f78;
  font-size: medium;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  width: 150px;
  height: 50px;
  position: absolute;
  top: 15px;
  display: inline-block;
}

#create-session-button {
  color: #ffffff;
  background-color: #ff0000;
  font-size: medium;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  width: 200px;
  height: 75px;
  display: block;
}

#create-session-button:hover {
  color: #ffffff;
  background-color: #fc7a7a;
  font-size: medium;
  border: none;
  border-radius: 10px;
  width: 200px;
  height: 75px;
  display: block;
}

#confirm-create {
  text-decoration: none;
  color: #ffffff;
  background-color: #ff0000;
  font-size: medium;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  width: 200px;
  height: 35px;
  display: block;
}

#confirm-create:hover {
  color: #ffffff;
  background-color: #fc7a7a;
  font-size: medium;
  border: none;
  border-radius: 10px;
  width: 200px;
  height: 35px;
  display: block;
}

#join-session-button {
  color: #ffffff;
  background-color: #0000ff;
  font-size: medium;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  width: 200px;
  height: 75px;
  display: block;
}

#join-session-button:hover {
  color: #ffffff;
  background-color: #7c7cf9;
  font-size: medium;
  border: none;
  border-radius: 10px;
  width: 200px;
  height: 75px;
  display: block;
}

#confirm-join {
  text-decoration: none;
  color: #ffffff;
  background-color: #0000ff;
  font-size: medium;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  width: 200px;
  height: 35px;
  display: block;
}

#confirm-join:hover {
  color: #ffffff;
  background-color: #7c7cf9;
  font-size: medium;
  border: none;
  border-radius: 10px;
  width: 200px;
  height: 35px;
  display: block;
}

.back-button {
  color: #ffffff;
  background-color: #5f5f78;
  font-size: medium;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  width: 200px;
  height: 35px;
  display: block;
}

.back-button:hover {
  color: #ffffff;
  background-color: #6a6a76;
  font-size: medium;
  border: none;
  border-radius: 10px;
  width: 200px;
  height: 35px;
  display: block;
}

.nuntius-emblem {
  position: absolute;
  top: 5px;
  left: 10px;
  height: 60px;
  width: auto;
}

.leave-session:hover {
  color: #ffffff;
  background-color: #6a6a76;
  font-size: medium;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  width: 150px;
  height: 50px;
  position: absolute;
  top: 15px;
  display: inline-block;
}

#the-send-msg-button {
  color: #ffffff;
  background-color: #5f5f78;
  font-size: medium;
  font-weight: bold;
  width: 1005px;
  height: 30px;
  border: none;
  border-radius: 10px;
}

#the-send-msg-button:hover {
  color: #ffffff;
  background-color: #6a6a76;
  border: none;
  border-radius: 10px;
}

.the-button-link {
  text-decoration: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

#the-button {
  width: 1005px;
  color: #000000;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
